import { render, staticRenderFns } from "./hy2.vue?vue&type=template&id=50aec4f8&scoped=true&"
import script from "./hy2.vue?vue&type=script&lang=js&"
export * from "./hy2.vue?vue&type=script&lang=js&"
import style0 from "./hy2.vue?vue&type=style&index=0&id=50aec4f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50aec4f8",
  null
  
)

export default component.exports